import React from 'react'
import './About.css'

function About_main() {
  return (
    <div>
      <section>
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <h1 className="mainHeadings">Future of Decentralized Exchange</h1>
              <br />
              <p class="Apara">
                momcoin supports a variety of the most popular digital currencies and has a variety of features that
                make it the best place to start trading With the intention of enhancing investor protection, a number of
                specialised blockchain security and auditing companies have teamed up with momcoin We at momcoin are
                Building the Future of Decentralized Exchanges.
              </p>
              <p class="Apara">momcoin is the easiest place to buy and sell cryptocurrency.</p>
              <h2 className="mainHeadings">momcoin</h2>
              <br />
              <p class="Apara text">
                The momcoin Token is a digital asset that connects the momcoin Platform and its core functionality. It
                serves as the official pair token on the platform and combines staking and incentives. Users will be
                able to trade and exchange their tokens for alternative assets with safety and ease after momcoin
                launches. momcoin will be the first digital money for common people, marking a significant advancement
                in the global use of cryptocurrencies.
              </p>
              <h2 className="mainHeadings">Has it been listed in any exchange?</h2>
              <br />
              <p class="Apara">
                As of now it can be purchased through our website which can be linked to trust wallet, very soon it will
                be listed in DEX exchange and then token would be tradable, very soon it will be listed in many more
                exchanges.
              </p>
              <h2 className="mainHeadings">Which Wallets Supports momcoin?</h2>
              <br />
              <p class="Apara">
                Many decentralised wallets, including MetaMask, TrustWallet, MathWallet, TokenPocket, WalletConnect,
                Binance Chain Wallet, and SafePal Walle, are fully compatible with momcoin and its token
              </p>
            </div>
            <div class="col-md-5">
              <img src="about.webp" width="110%" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h1 className="mainHeadings">Why choose us?</h1>
              <br />
            </div>

            <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>No sign-up</span>
            </div>
            <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>18 partners</span>
            </div>

            <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>More than 900 coins</span>
            </div>
            <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>Fixed and floating rates</span>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>No limits</span>
            </div>
            <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>24/7 support</span>
            </div>

            <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>Affiliate program</span>
            </div>
            <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>User-friendly interface</span>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <h1 className="mainHeadings">Why momcoin?</h1>
              <br />
            </div>

            <div class="col-md-6">
              <img src="f.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '18px' }}>
                  <b>Coin Support</b>
                </span>
                <br />
                <br />
                <p>
                  momcoin supports a variety of the most popular digital currencies and has a variety of features that
                  make it the best place to start trading. momcoin offers DeFi technologies so that enterprises and
                  token owners can confidently acquire, exchange more than 1000 cryptocurrency and tokens.
                </p>
              </span>
              <p></p>
            </div>
            <div class="col-md-6">
              <img src="f1.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '25px' }}>
                  <b>Security</b>
                </span>
                <br />
                <br />
                <p>
                  A number of specialised blockchain security and auditing businesses have partnered with momcoin with
                  the goal of strengthening investor protection. The momcoin Security Protocol is an end-to-end
                  transactional layer that supports digital non-fungible token payment infrastructure. With momcoin's
                  ultra-protected servers, you may trade discreetly with the assurance that your money is secure.
                </p>
              </span>
              <p></p>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-md-6">
              <img src="f2.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '25px' }}>
                  <b>Earning and Rewarding</b>
                </span>
                <br />
                <br />
                <p>
                  momcoin is the best passive income project, offering various methods to earn. As a fundamental element
                  of our diversified investing strategy, momcoin enables its clients to earn large risk-free income. The
                  way momcoin is set up seeks to attack every aspect of the cryptocurrency market as it exists right
                  now.
                </p>
              </span>
              <p></p>
            </div>
            <div class="col-md-6">
              <img src="f4.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '25px' }}>
                  <b>Cross chain Swapping</b>
                </span>
                <br />
                <br />
                <p>
                  momcoin is a gateway that enables token transfers between the top blockchains in the world. It
                  integrates cross chain swaps to provide traders access to multiple markets at once. momcoin enables
                  trading operations across numerous chains and investment in projects running across particular chains.
                </p>
              </span>
              <p></p>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-6">
              <img src="f5.webp" class="about-features__image" />
              <br />
              <span class="feature ">
                <span style={{ fontSize: '25px' }}>
                  <b>Low fees</b>
                </span>
                <br />
                <br />
                <p>
                  The fees at momcoin are among the lowest in the industry. A user will notice that 0.5% of their
                  transaction is being taken away when they conduct a swap. momcoin follows a fee-free governance model
                  and operates without any governance fees. Using miner fees, Swap encourages network security.
                </p>
              </span>
              <p></p>
            </div>
            <div class="col-md-6">
              <img src="f6.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '25px' }}>
                  <b>Easy to use</b>
                </span>
                <br />
                <br />
                <p>
                  With momcoin You can Swap your Crypto Tokens Cross-Chain In Just One Tap. momcoin enables its users to
                  swap native to native assets from one blockchain to another without needing to rely on a third party
                  by utilising cross-chain communication across blockchains.
                </p>
              </span>
              <p></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default About_main
