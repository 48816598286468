import React from "react";

function Token_main() {
  return (
    <div>
      <section>
        <div class="container">
          <div class="row">
            <br />
            <br />
            <div class="col-md-12">
              <h1 className="overviewMain-h2">Your Watchlist</h1>
            </div>
            <br />
            <div class="col-md-12">
              <div class="sc-de7e8801-1 sc-62e22978-1 dUUCVU ifuBaP">
                <div color="text" font-size="16px" class="sc-118b6623-0 iebHpO">
                  Saved Tokens will appear here
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-md-12">
              <h1 className="overviewMain-h2">Top Movers</h1>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 bottom">
              <div class="vnb2">
                <div class="">
                  <img src="arch.webp" width="30px" />
                </div>
                <div class="vbn1">
                  <h2 class="bnb">ARCH</h2>
                  <div class="vnb">
                    <div font-size="14px" color="text" class="hlDoPt">
                      $0.58
                    </div>
                    <div font-size="14px" color="success" class="gnpmdM">
                      ↑66.89%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 bottom">
              <div class="vnb2">
                <div class="">
                  <img src="alpha.webp" width="30px" />
                </div>
                <div class="vbn1">
                  <h2 class="bnb">ALPHA</h2>
                  <div class="vnb">
                    <div font-size="14px" color="text" class="hlDoPt">
                      $1.58
                    </div>
                    <div font-size="14px" color="success" class="gnpmdM">
                      ↑99.96%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 bottom">
              <div class="vnb2">
                <div class="">
                  <img src="e.png" width="30px" />
                </div>
                <div class="vbn1">
                  <h2 class="bnb">ETH</h2>
                  <div class="vnb">
                    <div font-size="14px" color="text" class="hlDoPt">
                      $0.88
                    </div>
                    <div font-size="14px" color="success" class="gnpmdM">
                      ↑12.01%
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3 bottom">
              <div class="vnb2">
                <div class="">
                  <img src="bnb1.png" width="30px" />
                </div>
                <div class="vbn1">
                  <h2 class="bnb">BNB</h2>
                  <div class="vnb">
                    <div font-size="14px" color="text" class="hlDoPt">
                      $0.56
                    </div>
                    <div font-size="14px" color="success" class="gnpmdM">
                      ↑120.05%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <div class="row">
            <div class="col-md-12">
              <h2 className="overviewMain-h2">All Tokens</h2>
              <br />
            </div>
            <br />
            <div class="col-md-12">
              <div class="sc-de7e8801-1 sc-1080dffc-0 sc-4dd460a5-1 dUUCVU bHahRd hBQwlD table-responsive">
                <div class="sc-ee964947-0 eRKwjz">
                  <div
                    color="secondary"
                    font-size="12px"
                    class="sc-118b6623-0 jaCbPP"
                  >
                    #
                  </div>
                  <div
                    color="secondary"
                    font-size="12px"
                    class="sc-118b6623-0 sc-4dd460a5-0 hkhVxk bdjWOI"
                  >
                    Name{" "}
                  </div>
                  <div
                    color="secondary"
                    font-size="12px"
                    class="sc-118b6623-0 sc-4dd460a5-0 hkhVxk bdjWOI"
                  >
                    Price{" "}
                  </div>
                  <div
                    color="secondary"
                    font-size="12px"
                    class="sc-118b6623-0 sc-4dd460a5-0 hkhVxk bdjWOI"
                  >
                    Price Change{" "}
                  </div>
                  <div
                    color="secondary"
                    font-size="12px"
                    class="sc-118b6623-0 sc-4dd460a5-0 hkhVxk bdjWOI"
                  >
                    Volume 24H ↓
                  </div>
                  <div
                    color="secondary"
                    font-size="12px"
                    class="sc-118b6623-0 sc-4dd460a5-0 hkhVxk bdjWOI"
                  >
                    Liquidity{" "}
                  </div>
                </div>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a
                  class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw"
                  href="/info/token/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
                >
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        1
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <img
                        class="sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        alt="token logo"
                        src="bnb1.png"
                      />
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          Wrapped BNB
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (WBNB)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $330.44
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="success"
                        font-size="16px"
                        class="sc-118b6623-0 hgBcOl"
                      >
                        ↑2.54%
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $205.08M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $2.29B
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw" href="">
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        2
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <img
                        class="sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        alt="token logo"
                        src="t.png"
                      />
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          Tether USD
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (USDT)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $0.99
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="failure"
                        font-size="16px"
                        class="sc-118b6623-0 igYoFk"
                      >
                        ↓0.01%
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $82.93M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $872.41M
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw" href="">
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        3
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <img
                        class="sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        alt="token logo"
                        src="b.png"
                      />
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          BUSD Token
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (BUSD)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $1.00
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="success"
                        font-size="16px"
                        class="sc-118b6623-0 hgBcOl"
                      >
                        ↑0.01%
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $82.62M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $1.39B
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw" href="">
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        4
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <img
                        class="sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        alt="token logo"
                        src="e.png"
                      />
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          Ethereum Token
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (ETH)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $1,877.99
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="success"
                        font-size="16px"
                        class="sc-118b6623-0 hgBcOl"
                      >
                        ↑11.37%
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $14.46M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $159.61M
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw" href="">
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        5
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <img
                        class="sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        alt="token logo"
                        src="s.png"
                      />
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          USD Coin
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (USDC)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $0.99
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="failure"
                        font-size="16px"
                        class="sc-118b6623-0 igYoFk"
                      >
                        ↓0.05%
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $10.61M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $246.10M
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw" href="">
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        6
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-8a800401-0 cziRfP sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        color="text"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          Grizzly Honey
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (GHNY)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $306.41
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="success"
                        font-size="16px"
                        class="sc-118b6623-0 hgBcOl"
                      >
                        ↑12.23%
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $8.73M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $29.48M
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw" href="">
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        7
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <img
                        class="sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        alt="token logo"
                        src="bitcoin.png"
                      />
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          BTCB Token
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (BTCB)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $24,445.10
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="success"
                        font-size="16px"
                        class="sc-118b6623-0 hgBcOl"
                      >
                        ↑6.38%
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $8.56M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $149.49M
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw" href="">
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        8
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <img
                        class="sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        alt="token logo"
                        src="p.png"
                      />
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          PancakeSwap Token
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (Cake)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $4.46
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="success"
                        font-size="16px"
                        class="sc-118b6623-0 hgBcOl"
                      >
                        ↑3.43%
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $5.68M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $192.61M
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw" href="">
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        9
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-8a800401-0 cziRfP sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        color="text"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          CPI
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (CPI)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $0.00045
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 kHjmAi"
                      >
                        -
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $4.22M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $57.06K
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <a class="sc-a01cb22d-0 bWjWCf sc-ee964947-1 ktvYZw" href="">
                  <div class="sc-ee964947-0 eRKwjz">
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU bHahRd">
                      <div
                        color="text"
                        font-size="16px"
                        class="sc-118b6623-0 eDwlOR"
                      >
                        10
                      </div>
                    </div>
                    <div class="sc-de7e8801-1 sc-1080dffc-0 dUUCVU cdKJAR">
                      <img
                        class="sc-b53c968a-0 eZPwOe sc-ee964947-2 hBFbAa"
                        alt="token logo"
                        src="d.png"
                      />
                      <div class="sc-de7e8801-1 sc-1080dffc-0 eqRXvM bHahRd">
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 eDwlOR"
                        >
                          TeddyDoge
                        </div>
                        <div
                          color="text"
                          font-size="16px"
                          class="sc-118b6623-0 jCxgaI"
                        >
                          (TEDDY)
                        </div>
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $0.000000000
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      <div
                        font-weight="400"
                        color="failure"
                        font-size="16px"
                        class="sc-118b6623-0 igYoFk"
                      >
                        ↓82.38%
                      </div>
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $3.62M
                    </div>
                    <div
                      font-weight="400"
                      color="text"
                      font-size="16px"
                      class="sc-118b6623-0 kHjmAi"
                    >
                      $23.14K
                    </div>
                  </div>
                </a>
                <div class="sc-4dd460a5-4 bjSNjt"></div>
                <div class="sc-4dd460a5-2 eiIMRG">
                  <div class="sc-4dd460a5-3 nlwrS">
                    <svg
                      viewBox="0 0 24 24"
                      color="textDisabled"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                      class="sc-8a800401-0 fRlDZe"
                    >
                      <path d="M19 11H7.82998L12.71 6.12C13.1 5.73 13.1 5.09 12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z"></path>
                    </svg>
                  </div>
                  <div
                    color="text"
                    font-size="16px"
                    class="sc-118b6623-0 eDwlOR"
                  >
                    Page 1 of 3
                  </div>
                  <div class="sc-4dd460a5-3 nlwrS">
                    <svg
                      viewBox="0 0 24 24"
                      color="primary"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                      class="sc-8a800401-0 exdUOd"
                    >
                      <path d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7C10.91 5.09 10.91 5.72 11.3 6.11L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Token_main;
