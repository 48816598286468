import React from 'react'
import './Nft.css'
import one from '../../assets/images/1.gif'
import two from '../../assets/images/2.gif'
import three from '../../assets/images/3.gif'
import four from '../../assets/images/4.gif'
import five from '../../assets/images/5.gif'

function Nft() {
  return (
    <div className="nft_main_bg">
      <div className="container">
        <div className="row reverse">
          <div className="col-xl-7 col-12 col-md-12">
            <div className="grp">
              <div className="leeft">
                <div>
                  <img className="item iteem" src={one} alt="" />
                </div>
                <div>
                  <img className="item iteem mb-3 mt-4" src={four} alt="" />
                </div>
                <div>
                  <img className="item iteem mb-3 mt-4" src={five} alt="" />
                </div>
              </div>
              <div className="rightt">
                <div>
                  <img src={two} className="item iteem" alt="" />
                </div>
                <div>
                  <img className="iteem item  mb-3 mt-4" src={three} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <h1 className="white text-start ps-2">NFT</h1>
            <br />
            <p className="Npara">Welcome to the most trusted Web3 Ecosystem for Gaming and NFTs.</p>
            <p className="Npara lh-0">
              MNFTswap is a dedicated NFT platform.With the objective of helping everyone to enjoy the value of
              digital assets, with both game-related NFTs and non-game NFTs MNFTswap is bringing an Open Market
              Platform where everyone in the world will be able to freely transact NFTs. All of your favourite brands
              and collectibles NFTs are available in one location. From games and dApps to exchanges and digital
              marketplaces, MNFTswap has everything a business needs to establish a successful NFT collection.
              MNFTswap open NFT Marketplace is your safe hotspot to the best NFT collections, dApps, video games,
              marketplaces, and more. It also makes it easier than ever to buy, sell, trade, and give NFTs.{' '}
            </p>
            <p className="Npara lh-0">
              The MNFTswap platform is designed in such a way that it combines the explosive growth of NFTs with
              DeFi's outstanding monetization capabilities. Participate and get MNFTcoin Tokens as a reward.
              MNFTswap supports non-fungible tokens via the open-source Simple Assets standard. MNFTswap NFTs
              service allows you to generate, mint, and store NFTs. All users can view their transactions on the chain,
              and customers may show to themselves that they were chosen fairly for random choices. We also provide a
              service that leverages the open-source Simple Assets NFT implementation.{' '}
            </p>
            <p className="Npara lh-0">
              The future includes full integration with other NFT standards. Interoperability maximizes liquidity for
              buyers and sellers and is the MNFTswap Platform's core value proposition.{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nft
