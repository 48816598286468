import React from 'react'
import './overview.css'
import MnftPic from '../../assets/images/MnftOver.png'
function Overview_main() {
  return (
    <div>
      <section>
        <div class="container ">
          <div class="row ">
            <div class="col-md-6">
              <h1 className="mainHeadings">MNFT Swap Protocol</h1>
              <br />
              <p class="Apara">
                Mnftswap aims to be a cross-chain decentralized exchange aiming to bring lightspeed swaps at the touch
                of a button.
              </p>
              <a
                href="https://bscscan.com/address/0x3f614D3F934c2a418d1c59852ec9fC57DF0f1fE7#code"
                class="content_link mb-5"
                style={{ color: 'red' }}
              >
                0x3f614D3F934c2a418d1c59852ec9fC57DF0f1fE7
              </a>
              <br />
              <h2 className="mainHeadings">What is Mnftswap Protocol?</h2>
              <br />
              <p class="Apara text">
                Mnftswap is a multi chain protocol that aims to facilitate swaps between more than 10,000 tokens on
                different blockchains such as Ethereum, Binance Smart chain, Solana, Polygon, Fantom, Avalanche in 1
                touch.
              </p>
              <p class="Apara text">
                MOMCOIN is a governance token for Mnftswap protocol, all fees and internal accounting will be in
                MOMCOIN.
              </p>
              <p class="Apara text">Users will need MOMCOIN to execute swaps between different chains.</p>
              <h2 className="mainHeadings">Has it been listed in any exchange?</h2>
              <br />
              <p class="Apara">
                As of now it can be purchased through our website which can be linked to trust wallet, very soon it will
                be listed in DEX exchange and then token would be tradable, very soon it will be listed in many more
                exchanges.
              </p>
              <h2 className="mainHeadings">Which Wallets Supports momcoin?</h2>
              <br />
              <p class="Apara">
                Many decentralised wallets, including MetaMask, TrustWallet, MathWallet, TokenPocket, WalletConnect,
                Binance Chain Wallet, and SafePal Walle, are fully compatible with momcoin and its token
              </p>
            </div>
            <div class="col-md-5">
              <img src={MnftPic} width="110%" alt="" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h1 className="mainHeadings">TOKENOMICS </h1>
            </div>

            <div class="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <span className="me-4" style={{ color: '#fff', fontSize: '23px ' }}>
                    Token Full Name:
                  </span>
                </div>
                <div className="col-md-3 col-sm-6">
                  <span style={{ color: '#fff' }}>MAD OVER MUSIC</span>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              {/* <svg
                data-v-70981b1d=""  
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg> */}
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <span className="me-4" style={{ color: '#fff', fontSize: '23px ' }}>
                    Token Name:
                  </span>
                </div>
                <div className="col-md-3 col-sm-6">
                  <span style={{ color: '#fff' }}>MOM (MAD OVER MUSIC)</span>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <span className="me-4" style={{ color: '#fff', fontSize: '23px ' }}>
                    Block Chain:
                  </span>
                </div>
                <div className="col-md-3 col-sm-6">
                  <span style={{ color: '#fff' }}>BINANCE SMART CHAIN (BSC)</span>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <span className="me-4" style={{ color: '#fff', fontSize: '23px ' }}>
                    Standard:
                  </span>
                </div>
                <div className="col-md-3 col-sm-6">
                  <span style={{ color: '#fff' }}>BEP20</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <span className="me-4" style={{ color: '#fff', fontSize: '23px ' }}>
                    Total Token Supply:
                  </span>
                </div>
                <div className="col-md-3 col-sm-6">
                  <span style={{ color: '#fff' }}>20 LAC</span>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <span className="me-4" style={{ color: '#fff', fontSize: '30px ', fontWeight: '500' }}>
                      TOTAL SUPPLY
                    </span>
                  </div>
                  <div className="mt-3" style={{ color: '#fff', fontSize: '19px ' }}>
                    TOTAL SUPPLY 20 LAC
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-12 ">
                    <span className="me-4" style={{ color: '#fff', fontSize: '19px ' }}>
                      19 LAC LOCK For 15 Year
                    </span>
                    <div className="mt-3" style={{ color: '#fff', fontSize: '19px ' }}>
                      19 LAC / 15 Year = 126666.66 Per Year
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <span style={{ color: '#fff', fontSize: '19px ' }}>126666.66 / 365 Days = 347.03 Per Day</span>
                </div>
              </div>
            </div>
            {/* <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>5% Development Fund</span>
            </div> */}

            {/* <div class="col-md-3 mt-3">
              <svg
                data-v-70981b1d=""
                data-v-0e11c1f7=""
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="check about-choosing__check"
                data-v-49591a1a=""
              >
                <path
                  data-v-70981b1d=""
                  d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
                ></path>
              </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>30% Floating Liquidity</span>
            </div> */}
            {/* <div class="col-md-3 mt-3">
              // <svg
              //   data-v-70981b1d=""
              //   data-v-0e11c1f7=""
              //   width="15"
              //   height="15"
              //   viewBox="0 0 15 15"
              //   fill="none"
              //   xmlns="http://www.w3.org/2000/svg"
              //   class="check about-choosing__check"
              //   data-v-49591a1a=""
              // >
              //   <path
              //     data-v-70981b1d=""
              //     d="M12.8134 2.39638L5.26113 9.94862L2.48616 7.17365C2.35162 7.03911 2.13347 7.03911 1.9989 7.17365L1.18684 7.98571C1.0523 8.12025 1.0523 8.33839 1.18684 8.47296L5.01748 12.3036C5.15202 12.4381 5.37017 12.4381 5.50474 12.3036L14.1126 3.69569C14.2472 3.56115 14.2472 3.343 14.1126 3.20844L13.3006 2.39638C13.166 2.26184 12.9479 2.26184 12.8134 2.39638Z"
              //   ></path>
              // </svg>
              <span style={{ position: 'absolute', color: '#fff' }}>User-friendly interface</span>
            </div> */}
          </div>
          <br />
          <div class="row">
            <div class="col-md-7">
              <h1 className="mainHeadings">OUR VISION</h1>
              <br />
              <p class="Apara">
                At MOMCOIN We endeavour to abolish Financial Constraints providing next gen Successor to obsolete
                traditional methodology of financial transactions by providing the most advanced yet simple to use
                platform providing specifically designed.
              </p>
              {/* <p class="Apara">0x6Fd6da3FB0aab30e032354152A6D7BE7Fb551484</p> */}
              <h2 className="mainHeadings">OUR MISSION </h2>
              <br />
              <p class="Apara text">
                We endeavour to abolish Financial Constraints providing next gen Successor to obsolete traditional
                methodology of financial transactions by providing the most advanced yet simple to use platform
                providing specifically designed tools catering to all segments of society ranging from multiple projects
                including NFT MARKET PLACE, MOMCOIN META LAND, ISO MUSIC NFTS , Mnftswap EXCHANGE, METAVERSE GAMING
                catering to crypto enthusiasts while having an actual use case of our native token MOMCOIN.
              </p>
            </div>
            {/* <div class="col-md-5">
              <img src="about.webp" width="110%" />
            </div> */}
          </div>
          {/* <div class="row">
            <div class="col-md-12">
              <h1 className="mainHeadings">OUR VISION</h1>
              <br />
            </div>

            <div class="col-md-6">
              <img src="f.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '18px' }}>
                  <b>Coin Support</b>
                </span>
                <br />
                <br />
                <p>
                  momco supports a variety of the most popular digital currencies and has a variety of features that
                  make it the best place to start trading. momco offers DeFi technologies so that enterprises and
                  token owners can confidently acquire, exchange more than 1000 cryptocurrency and tokens.
                </p>
              </span>
              <p></p>
            </div>
            <div class="col-md-6">
              <img src="f1.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '25px' }}>
                  <b>Security</b>
                </span>
                <br />
                <br />
                <p>
                  A number of specialised blockchain security and auditing businesses have partnered with momco with
                  the goal of strengthening investor protection. The momco Security Protocol is an end-to-end
                  transactional layer that supports digital non-fungible token payment infrastructure. With momco's
                  ultra-protected servers, you may trade discreetly with the assurance that your money is secure.
                </p>
              </span>
              <p></p>
            </div>
          </div> */}
          <br />
          {/* 
          <div class="row">
            <div class="col-md-6">
              <img src="f2.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '25px' }}>
                  <b>Earning and Rewarding</b>
                </span>
                <br />
                <br />
                <p>
                  momco is the best passive income project, offering various methods to earn. As a fundamental
                  element of our diversified investing strategy, momco enables its clients to earn large risk-free
                  income. The way momco is set up seeks to attack every aspect of the cryptocurrency market as it
                  exists right now.
                </p>
              </span>
              <p></p>
            </div>
            <div class="col-md-6">
              <img src="f4.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '25px' }}>
                  <b>Cross chain Swapping</b>
                </span>
                <br />
                <br />
                <p>
                  momco is a gateway that enables token transfers between the top blockchains in the world. It
                  integrates cross chain swaps to provide traders access to multiple markets at once. momco enables
                  trading operations across numerous chains and investment in projects running across particular chains.
                </p>
              </span>
              <p></p>
            </div>
          </div> */}
          <br />
          {/* <div class="row">
            <div class="col-md-6">
              <img src="f5.webp" class="about-features__image" />
              <br />
              <span class="feature ">
                <span style={{ fontSize: '25px' }}>
                  <b>Low fees</b>
                </span>
                <br />
                <br />
                <p>
                  The fees at momco are among the lowest in the industry. A user will notice that 0.5% of their
                  transaction is being taken away when they conduct a swap. momco follows a fee-free governance model
                  and operates without any governance fees. Using miner fees, Swap encourages network security.
                </p>
              </span>
              <p></p>
            </div>
            <div class="col-md-6">
              <img src="f6.webp" class="about-features__image" />
              <br />
              <span class="feature">
                <span style={{ fontSize: '25px' }}>
                  <b>Easy to use</b>
                </span>
                <br />
                <br />
                <p>
                  With momco You can Swap your Crypto Tokens Cross-Chain In Just One Tap. momco enables its users
                  to swap native to native assets from one blockchain to another without needing to rely on a third
                  party by utilising cross-chain communication across blockchains.
                </p>
              </span>
              <p></p>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default Overview_main
